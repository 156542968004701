/* 
 * 接口统一集成模块
 */
import * as code from './modules/code'
import * as user from './modules/user'
import * as work_order from './modules/work_order'
import * as fans_link from './modules/fans_link'
import * as fans_label from './modules/fans_label'
import * as combo_order from './modules/comboOrder';


// 默认全部导出
export default {
  code,
  user,
  work_order,
  fans_link,
  fans_label,
  combo_order,
};
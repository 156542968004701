export default {
  codeList1: '邀请码管理',
  codeList2: '添加邀请码(子账号)',
  codeList3: '邀请码：',
  codeList4: '请输入邀请码',
  codeList5: '状态：',
  codeList6: '备注：',
  codeList7: '请输入备注',
  codeList8: '查找',
  codeList9: '重置',
  codeList10: '邀请码',
  codeList11: '可用端口',
  codeList12: '无限制',
  codeList13: '状态',
  codeList14: '操作',
  codeList15: '查看',
  codeList16: '分享',
  codeList17: '编辑',
  codeList18: '创建工单',
  codeList19: '删除',
  codeList20: '新建',
  codeList21: '分享链接',
  codeList22: '删除任务',
  codeList23: '确认要删除吗？',
  codeList24: '操作成功',
  codeList25: '复制成功!',
  codeList26: '创建工单',
  codeList27: '已有开启工单',
  codeList28: '活链数量',
  codeList29: '创建时间',
  codeList30: '在线账号/总账号',
  codeList31: '粉丝总量(已去重)',
  codeList32: '当日入粉(已去重)',
  codeList33: '备注',
  codeList34: '状态',
  codeList35: '当前模式',

  codeList36: '分享密码',
  codeList37: '开启计数功能',
  codeList38: '重置数据',
  codeList39:
    '确认执行此操作后，所有新粉丝数据将被清除并转变为底粉，新粉丝总数将发生变化。并且不可恢复，您是否确认继续？',

  codeList40: '开启计数器功能',
  codeList41: '是',
  codeList42: '否',

  codeList43: '开启计数器功能后才能分享',
  codeList44: '当前套餐不支持',
  codeList45: '账号类型',
  codeList46: '主账号',
  codeList47: '子账号',
};
<template>
  <el-dialog
    v-model="dialogVisible"
    :close-on-click-modal="clickModal"
    :show-close="false"
    destroy-on-close
    :width="width"

    :custom-class="['rounded-lg dialog-style', show_footer+'footer']"
  >
  <template #title  >
    <div class="d_header">
      <el-icon :size="24" @click="handleClose" class="close_btn">
          <svg-icon icon-class="svg-dialog_close"/>
      </el-icon>
      <span class="el-dialog__title">{{ title }}</span>
    </div>
  </template>
    <slot/>
    <template #footer v-if="show_footer">
      <span class="dialog-footer">
        <el-button class="btn-style btn-submit button-style-bule-hove" type="primary" @click="handleSubmit" :loading="isLoading">{{t('components5')}}</el-button>
        <el-button class="btn-style btn-cancel"  @click="handleClose">{{ t('components6') }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { dialogProps } from './props'
import { useCommon } from '/@/hooks'
import { computed, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } =useI18n()
const { emitter} = useCommon()
const props = defineProps(dialogProps)
const emits = defineEmits(['update:show','update:loading'])

const dialogVisible = computed({
  get: () => props.show,
  set: val => {
    emits('update:show', val)
  }
})
const isLoading = computed({
  get: () => props.loading,
  set: val=> {
    emits('update:loading', val)
  }
})
const handleClose = () => {
  dialogVisible.value = false
}
const handleSubmit = () => {
  isLoading.value = true
  emitter.emit('submit')
}
onBeforeUnmount(() => {
   emitter.all.clear()
})
</script>
<style lang="postcss">
  .dialog-style {
    .el-dialog__header{
      text-align: center;
    }

    .el-dialog__title{
      margin-top: 20px;
      display: block;
      color: #262E44;
      font-size: 22px;
      font-weight: 500;
    }
    .close_btn{
      position: absolute;
      right: 15px;
      top:15px;
    }
    .btn-style{
      height: 36px;
      min-width: 90px;
    }
    .btn-cancel{
      border: 1px solid #1E75FF;
      background-color: #ffffff;
      color: #1E75FF;
    }
    .el-dialog__footer{
      padding: 0 0 30px;
      text-align: center;
    }
    .el-dialog__body .el-form-item__label{
      word-wrap: break-word;
      word-break: normal;
    }
    .el-dialog__body .el-form-item__content{
      word-wrap: break-word;
      word-break: normal;
    }
  }
  .truefooter .el-dialog__body{
    padding-bottom: 0;
  }


</style>
